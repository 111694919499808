import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        filters: [],
        selectedFilters: [],
    },
    getters: {
        getFilters(state) { return state.filters },
        getSelectedFilters(state) { return state.selectedFilters },
    },
    mutations: {
        createFilters(state, payload) {
            state.filters = payload.map((el) => {
                return {
                    ...el,
                    type: el.key === 'materials' ? 'search' : el.key === 'price' ? 'range' : 'tag',
                    open: false,
                    items: el.items.map((el, idx) => {
                        return {
                            ...el,
                            id: el.id ? el.id : el.period ? el.period : idx,
                            selected: false,
                            val: null
                        }
                    })
                }
            })
        },
        createSelectedFilters(state, payload) {
            let selectedFilters = [];
            payload.forEach(item => {
                selectedFilters.push({ key: item.key, items: [] });
            });
            state.selectedFilters = selectedFilters
        },
        updateFilters(state, payload) {
            let { key, filter, val = '', searched = [], valid } = payload
            for (let el of state.filters) {
                if (el.key === key && key === 'materials') {
                    if (searched.length) {
                        el.items = searched.map(el => {
                            return {
                                ...el,
                                selected: true
                            }
                        })
                    } else if (!val) {
                        el.items.forEach(element => {
                            if (element.id === filter.id) {
                                element.selected = !element.selected
                            }
                        });
                    }

                    break;
                }
                else if (el.key === 'price' && (key === 'price_start' || key === 'price_end' || key === 'price')) {
                    el.items.forEach(element => {
                        if (element.id === filter.id) {
                            element.selected = !val || !valid ? false : true;
                            element.val = val ? val : ''
                        }
                    });
                    break;
                }
                else if (el.key === key) {
                    el.items.forEach(element => {
                        if (element.id === filter.id) {
                            element.selected = !element.selected
                        }
                    });
                    break;
                }
            }
        },
        updateSelectedFilters(state, payload) {
            let selectedFilters = state.selectedFilters;
            let idx = 0
            for (let el of state.filters) {
                let items = []
                el.items.forEach(element => {
                    if (element.selected) {
                        items.push(element)
                    }
                });
                selectedFilters[idx].items = items
                idx++
            }
            state.selectedFilters = selectedFilters
        },

        clear(state) {
            for (let el of state.filters) {
                el.items.forEach(element => {
                    element.selected = false
                    element.val = ''
                });
            }
        }
    },
    actions: {
        async apiGetFiltersBlock({ commit }, { list, type }) {
            await Vue.axios.get(`/api/public/filters?list=${list}&type=${type}`)
                .then((res) => res.data)
                .then((data) => {
                    commit('createFilters', data.response)
                    commit('createSelectedFilters', data.response)
                })
        },
    }
}