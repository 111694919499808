<template>
  <div class="page">
    <header
      class="header"
      :class="{
        'header--background': scrollPosition > 100,
      }"
    >
      <div class="header__top">
        <router-link to="/" class="logo__link">
          <Logo class="logo"></Logo>
        </router-link>
        <div class="menu" v-if="menu">
          <IconButton
            v-if="!buttonClose"
            class="menu__button button--lg button--icon-white"
            @click="$router.push({ path: '/search' })"
          >
            <!-- @click="searchActive = !searchActive" -->
            <IconSearch></IconSearch>
          </IconButton>
          <IconButton class="menu__button button--lg button--icon-white" @click="showMenu">
            <IconMenu></IconMenu>
          </IconButton>
          <IconButton
            v-if="buttonClose"
            class="menu__close button--md button--outline-white"
            @click="$router.go(-1)"
          >
            <IconClose width="18" height="18"></IconClose>
          </IconButton>
        </div>
      </div>
      <div class="header__bottom">
        <div
          class="breadcrumbs"
          :class="{
            'breadcrumbs--background': scrollPosition > 100,
          }"
        >
          <template v-for="(item, index) in breadcrumbs">
            <router-link
              v-if="index === 0"
              class="breadcrumbs__link"
              :key="`breadcrumb-${index}`"
              :to="item.to"
            >
              <IconHome class="breadcrumbs__home"></IconHome>
            </router-link>
            <template v-else>
              <div class="breadcrumbs__item" :key="`breadcrumb-${index}`">
                <IconArrowTop
                  class="breadcrumbs__arrow"
                  :key="`arrow-${index}`"
                  width="11"
                  height="7"
                  color="#ffffff"
                >
                </IconArrowTop>
                <router-link
                  v-if="item.clickable"
                  class="breadcrumbs__link breadcrumbs__link--clickble"
                  :key="`breadcrumb-${index}`"
                  :to="item.to"
                >
                  {{ item.title }}
                </router-link>
                <p v-else class="breadcrumbs__link">{{ item.title }}</p>
              </div>
            </template>
          </template>
        </div>
        <ShareButton v-if="shareButton" style="margin-right: 12px" />
      </div>
    </header>
    <slot></slot>
    <Footer :footer="footer"></Footer>
    <MenuNavigation
      :menuActive="menuActive"
      @hidenMenu="setOpenMenu(false)"
      @showNewsletter="subscriptionActive = true"
    ></MenuNavigation>
    <EmailSubscription
      :subscriptionActive="subscriptionActive"
      @hidenSubscription="subscriptionActive = !subscriptionActive"
    ></EmailSubscription>
    <Search :searchActive="searchActive" @hidenSearch="hidenSearch" @showMenu="showMenu"></Search>
    <CookiesPopup></CookiesPopup>
  </div>
</template>
<script>
import { initAccessWidget } from "@/plugins/accessWidget/accessWidget.js";

import Logo from "@/components/icons/Logo.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconSearch from "@/components/icons/IconSearch.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import IconHome from "@/components/icons/IconHome.vue";
import Footer from "@/components/Footer.vue";
import MenuNavigation from "@/components/menu/MenuNavigation.vue";
import EmailSubscription from "@/components/menu/EmailSubscription.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Search from "@/components/menu/Search.vue";
import CookiesPopup from "@/components/CookiesPopup.vue";
import ShareButton from "@/components/ShareButton.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Logo,
    IconButton,
    IconSearch,
    IconMenu,
    IconArrowTop,
    IconHome,
    Footer,
    MenuNavigation,
    EmailSubscription,
    Search,
    IconClose,
    CookiesPopup,
    ShareButton,
  },
  data() {
    return {
      scrollPosition: null,
      searchActive: false,
      subscriptionActive: false,
    };
  },
  computed: {
    ...mapGetters({
      breadcrumbs: "getBreadcrumbs",
      menuActive: "getMenuValue",
    }),
    footer() {
      return this.$route.meta.footer ?? true;
    },
    menu() {
      return this.$route.meta.menu ?? true;
    },
    buttonClose() {
      return this.$route.meta.buttonClose ?? false;
    },
    shareButton() {
      return this.$route.meta.shareButton ?? false;
    },
  },
  created() {
    const { search } = this.$route.query;
    if (search) this.searchActive = true;
  },
  mounted() {
    initAccessWidget();
    window.addEventListener("scroll", this.updateScroll);
  },
  watch: {
    "$route.path": function () {
      this.setBreadcrumbs([]);
      this.setOpenMenu(false);
      this.searchActive = false;
      window.scroll(0, 0);
    },
    "$route.query.search": function () {
      if (this.$route.query?.search) this.searchActive = true;
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbs", "setOpenMenu"]),
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    hidenSearch() {
      this.$router.push({ path: "", query: {} });
      this.searchActive = false;
    },
    showMenu() {
      this.setOpenMenu(true);
      this.searchActive = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background-color: $lightBlack;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}
.header {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  padding: 4rem 10.5rem 2rem;
  transition: all 0.3s ease;
  width: 100%;
  @media only screen and (max-width: $md) {
    padding: 4rem 5rem 2rem;
  }
  @media (max-width: $sm) {
    padding: 20px;
  }
  &--background {
    padding: 1.5rem 10.5rem;
    background-color: rgba($color: $black, $alpha: 0.5);
    backdrop-filter: blur(8px);
    @media only screen and (max-width: $md) {
      padding: 1.5rem 5rem;
    }
    @media only screen and (max-width: $sm) {
      padding: 20px;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @media (max-width: $xs) {
      justify-content: flex-end;
    }
    @media only screen and (max-width: $sm) {
      display: none;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  & > * {
    margin: 5px 0;
  }
  @media screen and (max-width: $xxs) {
    position: absolute;
    margin: 0;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    padding: 0 25px 15px;
    width: 100%;
    &--background {
      background-color: rgba($color: $black, $alpha: 0.5);
    }
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__link {
    position: relative;
    color: $white;
    font-family: $fontFrutiger;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.56;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 20px;
    max-width: 60vw;
    overflow: hidden;
    @media (max-width: $xs) {
      font-size: 14px;
      line-height: 1.4;
      margin: 0 15px;
      max-width: 100%;
    }
    // &--clickble {
    //   &::after {
    //     position: absolute;
    //     content: "";
    //     bottom: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 1px;
    //     background: $white;
    //   }
    // }
  }
  & > .breadcrumbs__link {
    min-width: 25px;
    margin: 0 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__home {
    width: auto;
    height: auto;
  }
  &__arrow {
    width: auto;
    height: auto;
    transform: rotate(90deg);
  }
}
.menu {
  display: flex;
  &__button {
    border: none;
    border-radius: 50%;
    background-color: transparent;
    height: 6.4rem;
    width: 6.4rem;
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
  }
  .button:first-of-type {
    margin-right: 1rem;
  }
}

.logo {
  display: inline-block;
  height: 6.4rem;
  width: max-content;
  max-width: 158px;
  &__link {
    display: block;
  }
}
</style>

